<template>
  <div class="mobile-wrap" ref="homePage">
    <!-- <div id="app"> -->
    <div v-if="platform === 'mobile'">
      <div class="pic">
        <img :src="eilisProfile.logo" style="margin-left: 5px; margin-top: -20%;" alt="louisa logo"  width="100%">
      </div>
       <!-- AuthToken -->
      <!-- <cube-textarea class="item" :placeholder="transform" disabled >
      </cube-textarea> -->
        <!-- 選擇國碼 -->
        <cube-select class="item" icon="perm_identity" @change="change" v-model="countryCode" confirmTxt="確認" title="請選擇" placeholder="選擇國碼" :options="countries" :disabled="phoneVerify">
          <!-- <mu-option v-for="(code, index) in countries" :key="index" :label="$t(code.text)" :value="code.value"></mu-option> -->
        </cube-select>
        <!-- 輸入電話 -->
        <!-- <cube-input   class="item"  v-model="phoneNumber"  :placeholder="$t('Login.authHint') + countries.filter(el => el.value === countryCode).map(el => el.example)[0]" :disabled="phoneVerify">
        </cube-input> -->
        <cube-input  class="item" :maxlength="10" v-model="phoneNumber"  :placeholder="place(countryCode)" :disabled="phoneVerify">
        </cube-input>
        <!-- <div class="recaptcha-container" id="recaptcha-container"></div> -->
        <!-- 認證按鈕 -->
        <cube-button id="sign-in-button" class="item"  style="transform: translateY(0px);" @click="checkingPhone"  :disabled="phoneNumber === '' || number !== ''">{{ $t('Login.verify') }} {{number}}</cube-button>
        <!-- <cube-textarea style="top: -15px;" class="item" icon="account_circle" label-float  label="請輸入真實姓名" v-model="name" v-if="phoneVerify">
        </cube-textarea> -->
        <!-- 輸入驗證碼 -->
        <cube-input   class="item" placeholder="請輸入驗證碼" v-model="verCode" v-if="phoneVerify" ></cube-input>
        <cube-button id="sign-in-button" class="item" v-if="phoneVerify" size="medium" style="transform: translateY(0px);" @click="codeVer" >{{ $t('Login.verify') }}</cube-button>
      <br><br>
      <div class="bottom">
        <img src="../img/eilis.png" alt="Smiley face" height="14" width="">
        <div style="transform: ｀cale(0.7)"> 智慧互動助理提供</div>
      </div>
      <cube-loading :size="25" class="loadingIcon" v-if="loading"></cube-loading>
      <div id="layer" v-if="loading"/>
    </div>
    <!-- QR Code -->
    <div v-if="platform === 'desktop'">
      <div style="border: 1px solid #cbced3; display: inline-block; padding: 20px; padding-bottom: 12px; margin-top: 40px; width: 200px;">
        <qrcode-vue :value="qrcode" size="150" level="H"></qrcode-vue>
      </div>
      <div style="margin-top: 15px;">
        {{ $t('Login.no_service') }}<br>{{ $t('Login.scan_code') }}
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapMutations, mapGetters, mapActions } from 'vuex'
import axios from 'axios';

const CryptoJS = require('crypto-js');

export default {
  data () {
    return {
      // EMC LITE
      logo: '',
      //
      availHeight: '',
      crossPlatform: false,
      phone: '',
      //
      confirmOcardUser: '',
      userId: '',
      number: '',
      tempCode: '',
      phoneVerify: false,
      loading: false,
      value: '',
      platform: '',
      verCode: '',
      AuthToken: this.$route.params.Auth_u,
      Service: this.$route.params.service,
      alert: false,
      alertmessage: '電話號碼還未輸入喔！',
      countryCode: '886',
      noVerify: this.$route.query.noVer ? this.$route.query.noVer : false,
      channelData: {
        AuthToken: this.$route.query.Auth,
        Service: this.$route.params.service,
        // PhoneNumber: '',
        Name: this.$route.query.Name ? this.$route.query.Name : '',
        PageID: this.$route.query.Page,
        trigger: this.$route.query.trigger,
        Img: this.$route.query.Img,
        transactionId: this.$route.query.transactionId,
        Point: this.$route.query.Point
      },
      countries:[
        {
          text: '台灣(+886)',
          value: '886',
          example: '0912345678'
        },
        {
          text: '柬埔寨(+855)' ,
          value: '855',
          example: '091234567'
        },
        // {
        //   text: '馬來西亞(+60)',
        //   value: '60',
        //   example: '0123456789'
        // }
      ],
    }
  },
  mounted() {
    console.log(this.personalData);
    console.log(this.eilisProfile);
    // this.logo = this.eilisProfile.logo
    this.platform = 'mobile';
    // console.log(localStorage.getItem('brand'));
    this.availHeight = `${window.screen.availHeight}`;
    this.$refs.homePage.style.height = this.availHeight+ 'px';
    // console.log(this.availHeight+ 'px');
  },
  watch: {
    countryCode: {
      handler: function(countryCode) {
        console.log(countryCode);
        if (countryCode === '886') {
          this.storeCountryCode = this.countryCode;
        }
      },
      deep: true,
      immediate: true
    },
    // 監聽驗證碼輸入為6碼
    verCode(newV) {
      if (newV.length > 6) {
        newV = newV.slice(0, 6)
        this.$nextTick(() => {
          this.verCode = newV
        })
      }
    },
    phoneNumber(val) {
      const re = /^[0-9]+$/;
      if (!re.test(val)) {
        this.Alert();
        this.$nextTick(() => {
          this.phoneNumber = '';
        })
      }
    },
  },
  computed: {
    ...mapGetters({
      eilisProfile: 'eilisProfile',
      info: 'info',
      personalData: 'personalData',
      brandData: 'brandData',
      OcardUser: 'OcardUser',
      shopDetail: 'shopDetail'
    }),
    transform() {
      // watch 無法監聽物件, computed不能修改component的值, 所以顯示會用placeholder
      if (this.channelData.Service === 'LINE') {
        return this.channelData.AuthToken.substr(5,10);
      } else {
        return this.channelData.AuthToken
      }
    },
    individualData: {
      get () { return this.personalData },
      set(value) { this.storepersonal({ personal: value }); },
    },
    storeShopContent: {
      get () { return this.shopDetail },
      set(value) { this.updateShopContent({ shopDetail: value }); },
    },
    storeBrandData: {
      get () { return this.brandData },
      set(value) { this.updateBrandData({ brand: value }); },
    },
     updateOcardUser: {
      get () { return this.OcardUser },
      set(value) { this.storeOcardUser({ OcardUser: value }); }, 
    },
    token: {
      get () { return this.info.token },
      set(value) { this.updatetoken({ token: value }); },
    },
    phoneNumber: {
      get () { return this.info.phone },
      set(value) { this.updatephone({ phone: value }); },
    },
    storeCountryCode: {
      get () { return this.info.countryCode },
      set(value) { this.updateCountryCode({ code: value }); },
    },
    birth: {
      get () { return this.info.birth },
      set(value) { this.updatebirth({ birth: value }); },
    },
  },
  methods: {
    ...mapMutations({
      updatephone: 'updatephone',
      updatetoken: 'updatetoken',
      updateCountryCode: 'updateCountryCode',
      storepersonal: 'storepersonal',
      updateBrandData: 'updateBrandData',
      storeOcardUser: 'storeOcardUser',
      updateShopContent: 'updateShopContent'
    }),
    ...mapActions({
      checkAccountGetClientID: 'checkAccountGetClientID',
      checkUserByPhone: 'checkUserByPhone',
      ChTextMsg: 'ChTextMsg',
      checkMember: 'checkMember',
      checkUserByToken: 'checkUserByToken',
      TWtextmsg: 'TWtextmsg',
      getConfig: 'getConfig',
      fastClearance: 'fastClearance',
      register: 'register',
      Encrypt: 'Encrypt',

      readMember: 'readMember',
      updateMember: 'updateMember',
      registerEilis: 'registerEilis',
      updateMemberEilis: 'updateMemberEilis'
    }),
    // 解密
    Decrypt(word) {
      // console.log(word);
      var key = "FFzqekWVwgu7Oz28pfyPUlbOk4UusnNc"
      var iv = "2uY28N7v5kVEuFPr"
      const keysStr = CryptoJS.enc.Utf8.parse(key);
      const ivStr = CryptoJS.enc.Utf8.parse(iv);
      let encryptedHexStr = CryptoJS.enc.Base64.parse(word);
      let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
      let decrypt = CryptoJS.AES.decrypt(srcs, keysStr, {
          iv: ivStr,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      });
      let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
      // console.log(decryptedStr);
      return decryptedStr.toString();
    },
    place(val) {
      const word = '範例: ';
      if (val !== '') {
        return word + this.countries.filter(el => el.value === this.countryCode).map(el => el.example)[0]
      } else {
        return word + this.countries.filter(el => el.value === '+886').map(el => el.example)[0]
      }
    },
    a() {
      this.number -= 1;
    },
    b() {
      this.number = 300;
      var si = setInterval(this.a, 1000);
      setTimeout(() => {
        this.number = '';
        // this.phoneVerify = true;
        clearInterval(si);
      }, 300000);
    },
    Alert(val) {
      this.$createDialog({
        type: 'alert',
        title: '小提示',
        content: '格式有誤，請參考範例。',
        icon: 'cubeic-alert',
        confirmBtn: {
          text: '確定',
          active: true,
          disabled: false,
          href: 'javascript:;'
        },
      }).show()
    },
    showAlert(val) {
      this.$createDialog({
        type: 'alert',
        title: '小提示',
        content: val,
        icon: 'cubeic-alert',
        confirmBtn: {
          text: '確定',
          active: true,
          disabled: false,
          href: 'javascript:;'
        },
        // onConfirm: (e, promptValue) => {
        //   this.phoneAuth();
        // }
      }).show()
    },
    showAlert2(val) {
      if (val !== 'mobile tag error') {
        this.$createDialog({
        type: 'alert',
        title: '小提示',
        content: '手機格式有誤',
        confirmBtn: {
          text: '確定',
          active: true,
          disabled: false,
          href: 'javascript:;'
        },
        // icon: 'cubeic-alert'
        }).show()
      } else {
        this.$createDialog({
        type: 'alert',
        title: '小提示',
        content: val + '發生錯誤',
        confirmBtn: {
          text: '確定',
          active: true,
          disabled: false,
          href: 'javascript:;'
        },
        // icon: 'cubeic-alert'
        }).show()
      }
    },
    checkingPhone() {
      this.$loading.show()
      console.log(this.eilisProfile);
      if (this.phoneNumber.length === 10) {
      // console.log(this.eilisProfile);
     this.checkAccountGetClientID({ accountId: this.eilisProfile.accountId })
      .then((res) => {
          console.log(res);
          if (res.data.result == "缺少指定參數: accountId") {
            this.$loading.hide();
              this.$createDialog({
                type: "alert",
                title: "小提示",
                content: "系統更新當中(408333)",
                confirmBtn: {
                  text: "確定",
                  active: true,
                  disabled: false,
                  // href: "javascript:;",
                },
              }).show();
          }
          else if (typeof res.data.result !== 'object') {
            this.$loading.hide();
            this.$createDialog({
                type: "alert",
                title: "小提示",
                content: "　　 系統更新當中<br>"+res.data.result,
                confirmBtn: {
                  text: "確定",
                  active: true,
                  disabled: false,
                  // href: "javascript:;",
                },
              }).show();
          }
        if (res.data.result.clientId) {
          this.checkUserByPhone({ accountId: this.eilisProfile.accountId, phone: this.phoneNumber, clientId: res.data.result.clientId , })
          .then((res)=> {
          console.log(res);
          // if (this.phoneNumber === '0987747267') {
          //   this.$router.push(`/Register/${this.$route.params.service}?Auth=${this.$route.query.Auth}&Img=${this.channelData.Img}`)
          // }
          if (res.data.result === null ) {
            // 不是3.0會員
            this.$loading.hide()
            this.phoneAuth();
            // this.$router.push(`/Register/${this.$route.params.service}?Auth=${this.$route.query.Auth}&Img=${this.channelData.Img}`)
           } else if (res.data.result.isOcardUser) {
            // 是Ocard會員
            this.updateOcardUser = res.data.result
            // this.updateOcardUser.isOcardUser = res.data.result.isOcardUser
            // this.updateOcardUser.frequency = res.data.result.frequency
            // this.updateOcardUser.lastOrder = res.data.result.lastOrder
            // this.updateOcardUser.totalAmount = res.data.result.totalAmount
            // this.updateOcardUser.addTime = res.data.result.addTime
            this.$loading.hide()
            this.confirmOcardUser = true
            this.phoneAuth();
          }
          else if (res.data.result.isClientUser === false) {
            // 在這個集團底下沒有註冊過（G端），走一般註冊會員
            this.individualData = res.data.result;
            this.phoneAuth();
          }
          else if (res.data.result.service && res.data.result.service[this.$route.params.service]) {
            const content = '此電話已註冊'
            this.$loading.hide()
            this.showAlert(content)
          }
          // 3/17 快速通關
          else if (res.data.result.isClientUser === true) {
            this.userId = res.data.result.userId
            this.crossPlatform = true;
            this.phone = res.data.result.phone;
            this.$loading.hide()
            this.phoneAuth();
          }
          })
        }
      })
      } else {
        const content = '格式有誤'
        this.$loading.hide()
        this.showAlert(content)
      }
    },
    phoneAuth() {
      this.$loading.show()
      // console.log(this.equal);
      const sixcodes = `${Math.floor(Math.random()*(9-0+1))+0}${Math.floor(Math.random()*(9-0+1))+0}${Math.floor(Math.random()*(9-0+1))+0}${Math.floor(Math.random()*(9-0+1))+0}${Math.floor(Math.random()*(9-0+1))+0}${Math.floor(Math.random()*(9-0+1))+0}`;
      this.tempCode = sixcodes;
      if (this.crossPlatform === true) {
        this.b();
        this.phoneVerify = true;
        this.$loading.hide()
      }
      console.log(this.tempCode);
      // service:FB OR LINE ,gate: channel ID or FB ID
      const word = `成功登錄後，歡迎您升等為${this.eilisProfile.name}的數位會員`
      const words = `感謝您的申請，您的驗證碼為:`;
      // const words = `使用${this.brand}的驗證碼:`
      // console.log(words);
      // return;
      this.TWtextmsg({ phone: this.phoneNumber, code: words + sixcodes + word, service: this.$route.params.service, gate: this.eilisProfile.auth,})
      .then((res)=> {
        // console.log(res);
        if (res.data.text !== 'mobile tag error') {
          this.b();
          this.phoneVerify = true;
          this.$loading.hide()
        } else {
          this.showAlert2(res.data.text);
          this.$loading.hide()
          // window.location.reload();
        }
      });
    },
    codeVer() {
      this.$loading.show()
      if (this.verCode === this.tempCode) {
        // DEV NOTE:20210918MARK 目前這個解法是暫時解，目前如果已經註冊過的人，但當時沒有shopDetail(shopName & posid)的人，在這裏會去撈取localStorage.getItem('shopcontent')).shopName
        // 所以目前只要 this.shopDetail === undefined 的狀況，我們都視為網路行銷用，但是這樣必須要在，管理後台先預設一組店家，是網路行銷用，這是技術債，要回來看

        if(this.shopDetail === undefined || this.shopDetail === ''){
          this.storeShopContent = {
            "posId": "8888",
            "shopName": "網路行銷用"
          }
        }        
        console.log(`456 ${this.shopDetail}`)
        if (this.crossPlatform === true) {
          // alert("不錯喔 快速通關")
          // return;
          console.log(`458 crossPlatform`)
          // DEV NOTE:20210917MARK 合併馬力歐 
          console.log(`${this.channelData.Service}+${this.eilisProfile.clientId}+${this.channelData.AuthToken}+${this.eilisProfile.accountId}+${this.shopDetail}++1`)
          // DEV NOTE:20210916MARK 快速通關，如果是就有已經註冊過會員，沒有帶店號會卡死，this.shopDetail.shopName 會是undefine

          this.fastClearance({ service: this.channelData.Service ,clientId: this.eilisProfile.clientId, token: this.channelData.AuthToken, auth: this.eilisProfile.auth, accountId: this.eilisProfile.accountId, phone: this.phone, userId: this.userId, shopName: this.shopDetail ? this.shopDetail.shopName : JSON.parse(localStorage.getItem('shopcontent')).shopName, shopNo: this.shopDetail ? this.shopDetail.posId : JSON.parse(localStorage.getItem('shopcontent')).posId,})
          .then((res) => {
            if (res.data.status === 10020) {
              const A =  this.getConfig({accountId: this.eilisProfile.accountId})
              const B =  this.checkUserByToken({service: this.channelData.Service, token: this.channelData.AuthToken, auth: this.eilisProfile.auth, accountId: this.eilisProfile.accountId})
              Promise.all([A, B]).then(values => {
                console.log(values);
                // 加密暫存個人資料
                this.Encrypt(values[1].data.result).then((res) => {
                  localStorage.setItem('personalInfo', res)
                })
                this.individualData = values[1].data.result;
                this.storeBrandData = values[0].data.result;
                console.log(this.individualData);
                console.log(this.storeBrandData);
                localStorage.setItem('brandColor', this.storeBrandData.brandColor)
                localStorage.setItem('eiAuth', values[0].data.result.auth)
                this.$router.push(`/mainbottom/${this.$route.params.service}?Auth=${this.channelData.AuthToken}`);
                this.$loading.hide()
              })
            } else {
              var contents = res.data.result;
              this.showAlert(contents)
            }
          })
          .catch(() => {
            const toast = this.$createToast({
              txt: 'Loading.....',
              type: 'confirm'
            })
            toast.show()
            const A =  this.getConfig({accountId: this.eilisProfile.accountId})
            const B =  this.checkUserByToken({service: this.channelData.Service, token: this.channelData.AuthToken, auth: this.eilisProfile.auth, accountId: this.eilisProfile.accountId})
            Promise.all([A, B]).then(values => {
              console.log(values);
              if (values[1].data.result && values[0].data.result) {
                // 加密暫存個人資料
                this.Encrypt(values[1].data.result).then((res) => {
                  localStorage.setItem('personalInfo', res)
                })
                this.individualData = values[1].data.result;
                this.storeBrandData = values[0].data.result;
                console.log(this.individualData);
                console.log(this.storeBrandData);
                localStorage.setItem('brandColor', this.storeBrandData.brandColor)
                localStorage.setItem('eiAuth', values[0].data.result.auth)
                this.$router.push(`/mainbottom/${this.$route.params.service}?Auth=${this.channelData.AuthToken}`);
                this.$loading.hide()
              } else {
                const toast = this.$createToast({
                  txt: '請重新操作',
                  type: 'warn'
                })
                toast.show()
                this.phoneVerify = false;
                this.$loading.hide()
              }
            })
          })
        } else if (this.confirmOcardUser === true) {
          console.log(`523 confirmOldUser`)
          console.log(true);
          this.register({
          service: this.$route.params.service,
          token: this.$route.query.Auth,
          auth: this.eilisProfile.auth,
          accountId: this.eilisProfile.accountId,
          clientId: this.eilisProfile.clientId,
          phone: this.updateOcardUser.phone,
          name: this.updateOcardUser.name,
          birth: this.updateOcardUser.birth,
          gender: this.updateOcardUser.gender,
          shopName: this.shopDetail.shopName ?  this.shopDetail.shopName  : this.updateOcardUser.shopName,
          shopNo: this.shopDetail.posId ? this.shopDetail.posId : this.updateOcardUser.shopNo,
          profileImg: this.channelData.Img,
          // Azure Ocard會員
          addTime: this.updateOcardUser.addTime,
          lastOrder: this.updateOcardUser.lastOrder,
          totalAmount: this.updateOcardUser.totalAmount,
          frequency: this.updateOcardUser.frequency,
          isOcardUser: this.updateOcardUser.isOcardUser})
          .then((res) => {
          console.log(res);
          if (res.data.result && res.data.status === 10020) {
            const A =  this.getConfig({accountId: this.eilisProfile.accountId})
            const B =  this.checkUserByToken({service: this.$route.params.service, auth: this.eilisProfile.auth, token: this.$route.query.Auth, clientId: this.eilisProfile.clientId, accountId: this.eilisProfile.accountId})
            Promise.all([A, B]).then(values => {
              console.log(values);
              // 加密暫存個人資料
              this.Encrypt(values[1].data.result).then((res) => {
                localStorage.setItem('personalInfo', res)
              })
              this.storeBrandData = values[0].data.result;
              this.individualData = values[1].data.result;
              localStorage.setItem('brandColor', this.storeBrandData.brandColor)
              localStorage.setItem('eiAuth', values[0].data.result.auth)
              console.log(this.individualData);
              console.log(this.storeBrandData);
              this.$router.push(`/mainbottom/${this.$route.params.service}?Auth=${this.channelData.AuthToken}`);
              this.$loading.hide();
            })
          } else {
            const toast = this.$createToast({
              txt: '請重新操作',
              type: 'warn'
            })
            toast.show()
            this.$loading.show()
          }
          })
        }
        else if (this.individualData.length > 0) {
          console.log(`575 individualDataUser`)
          // 有在其他品牌註冊過 但該會員在此品牌尚未註冊，把會員資料讀取並貯存，電話驗證，打註冊會員
          this.register({ service: this.$route.params.service, token: this.$route.query.Auth , brandID: this.eilisProfile.auth,
            accountId: this.eilisProfile.accountId,
            clientId: this.eilisProfile.clientId,
            phone: this.individualData.phone,
            name: this.individualData.name,
            birth: this.individualData.birth,
            gender: this.individualData.gender,
            address: this.individualData.address,
            email: this.individualData.email,
            country: this.individualData.countryCode,
            county: this.individualData.county,
            district: this.individualData.district,
            zipcode: this.individualData.zipcode,
            remarks: this.individualData.remarks,
            profileImg: this.individualData.Img,
            carrier:  this.individualData.carrier})
            .then((res) => {
              console.log(res);
              if (res.data.result && res.data.status === 10020) {
                this.getConfig({accountId: this.eilisProfile.accountId})
                .then(res => {
                  console.log(values);
                  this.storeBrandData = res.data.result;
                  console.log(this.storeBrandData);
                  this.$router.push(`/mainbottom/${this.$route.params.service}?Auth=${this.channelData.AuthToken}`);
                })
              }
            })
        }
        else {
          console.log(`607 individualNewUser`)
          const A =  this.getConfig({accountId: this.eilisProfile.accountId})
          Promise.all([A]).then(values => {
            console.log(values);
            this.storeBrandData = values[0].data.result;
            console.log(this.storeBrandData);
          })
          this.$loading.hide()
          return this.$router.push(`/Register/${this.$route.params.service}?Auth=${this.$route.query.Auth}&Img=${this.channelData.Img}&Point=${this.channelData.Point}`)
        }
      } else {
        const str = '驗證碼有誤'
        this.showAlert(str);
        this.$loading.hide()
      }
    },
    change(value, index, text) {
      this.storeCountryCode = value;
    },
    chosen (val) {
      // console.log(val);
      this.value0 = this.options[val][0];
      // console.log(this.value0);
    },
  }
}
</script>

<style lang="scss" scoped>
  .recaptcha-container{
    opacity: 0%;
  }
  .mobile-wrap {
    margin: auto;
    width: 300px;
    height: 750px;
    width: 100%;
    overflow: scroll;
    position: relative;
  }
  .mobile-wrap:before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    position: absolute;
    // background: rgba(90, 93, 165, .8);
    // background: linear-gradient(to bottom, rgba(90, 93, 165, 1), rgba(0, 0, 0, .7))
  }
  .loadingIcon {
    z-index: 100000;
    display: flex; // 置中
    align-items: center; // 置中
    justify-content: center; // 置中
    margin: 0 auto;
    position: fixed;
    top: 50%;
    left: 0;
    right: 0;
    // margin-top: -270px;
    width: 55px;
    height: 55px;
    color: #ffffff;
    border-radius: 5px;
    background-color: #717171;
    -webkit-text-fill-color: transparent;
  }
  #layer{
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(159, 159, 159, 0.527);
  }
  .menu {
    padding-left: 0!important;
    padding-right: 0!important;
    margin: 0 auto;
    overflow: hidden;
    width: 100%;
    max-width: 540px;
    min-width: 300px;
    height: 750px;
    background-color: #ffffff;
    /* border: 1px solid rgb(170, 170, 170); */
    /* border:1px solid #8a8a8a; */
  }
  .pic {
    position: relative;
    z-index: 1;
    transform: scale(0.6);
    // top: 30px;
    margin-top: 30px;
    margin-left: 0px;
    height: 280px;
  }
  .item {
    // z-index: 1;
    // border-radius: 10px;
    font-size: 16px;
    width: 80%;
    max-width: 420px;
    min-width: 200px;
    margin: 0 auto 25px ;
  }
  section {
    position: relative;
    background-color: none;
    width: 100%;
    height: 100vh;
  }
  #curve {
    z-index: 1000;
    position: relative;
    transform: scale(4) rotate(6deg);
    bottom: 500px;
    left: 200px;
    width: 100%;
  }
  #curve path {
    fill: rgb(76, 182, 14);
  }
  .bottom {
    font-size: 5px;
    position: fixed;
    bottom: 10px;
    right: 0px;
    left: 0px;
  }
</style>
